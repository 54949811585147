<template>
	<div class="admin-container whole_fff whole_000">
		<Head MyLocal="location" @newLocal="location=$event" />
		<div class="esta">
			<div class="container">
				<div class="accr-top flex flex-ac">
					<i class="el-icon-arrow-left"></i>
					<p>{{$t('cadTxt')}}</p>
				</div>
				<div class="esta_tips">
					<p class="line-2">{{$t('cadTxt2')}}{{$t('cadTxt2_1')}}
						<router-link to="/trade/usdt">{{$t('cadLink2')}}</router-link>
					</p>
					<p class="ellipsis">{{$t('cadTxt2_2')}}</p>
					<p class="ellipsis">{{$t('cadTxt3')}}
						<router-link to="/ad">{{$t('cadLink3')}}</router-link>。
					</p>
				</div>
				<el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px"
					class="esta_form">
					<el-form-item :label="$t('cadLabel1')">
						<el-radio-group v-model="form.advertiseType">
							<el-radio :label="1">{{$t('radio')}}</el-radio>
							<el-radio :label="0">{{$t('radio2')}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('cadLabel2')">
						<el-select v-model="form.coinUnit" disabled :placeholder="$t('placeholder3')">
							<el-option v-for="(item,index) in coinList" :key="index" :label="item.unit"
								:value="item.unit">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('cadLabel3')">
						<el-select v-model="form.countryName" :placeholder="$t('placeholder3')" @change="selectChange">
							<el-option v-for="(item,index) in country" :key="index" :label="item.zhName"
								:value="item.zhName">
								<div class="select-item">
                  <span>{{location == 'zh_CN' ? item.zhName : item.enName}}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('cadLabel4')">
						<el-input v-model="form.localCurrency" :placeholder="$t('placeholder2')" :disabled="true">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('cadLabel5')">
						<el-switch v-model="form.priceType" :width="72" :active-value="0" :inactive-value="1"
							inactive-color="#E6E7EA" active-color="rgba(255,255,255,0)"></el-switch>
					</el-form-item>
					<el-form-item v-if="form.priceType == 0" prop="price" :label="$t('cadLabel15')">
						<el-input v-model="form.price" type="number" :placeholder="$t('cadplaceholder6')">
							<template slot="suffix">
								{{form.localCurrency}}
							</template>
						</el-input>
					</el-form-item>
					<div v-if="form.priceType == 0" style="padding-left: 100px;">
						<div class="form_pre">
							<span>{{$t('cadTxt4')}}：{{cankao}}</span>
							<span>{{$t('cadnew')}}</span>
						</div>
					</div>
					<el-form-item v-if="form.priceType == 1" prop="premiseRate" :label="$t('cadLabel6')">
						<el-input v-model="form.premiseRate"  type="number" :placeholder="$t('cadplaceholder')">
							<template slot="suffix">
								%
							</template>
						</el-input>
					</el-form-item>
					<div v-if="form.priceType == 1" style="padding-left: 100px;">
						<div class="form_pre">
							<span>{{$t('cadTxt4')}}：{{form.marketPrice}}</span>
							<span v-if="form.advertiseType == 1 ">{{$t('cadTxt5')}}</span>
							<span v-else>{{$t('cadTxt10')}}</span>
						</div>
					</div>
					<el-form-item :label="$t('cadLabel7')">
						<div class="form_tran">
							<p>{{price}}{{form.localCurrency}}/{{form.coinUnit}}</p>
							<span>{{$t('cadTxt6')}}：（Bitstamp+Bitfinex+Coinbase）/ 3 *{{gongshi.toFixed(4)}}</span>
						</div>
					</el-form-item>
					<el-form-item prop="number" :label="form.advertiseType == 1 ? $t('cadLabel8'): $t('cadLabel17')">
						<el-input v-model="form.number"  type="number"
							:placeholder="form.advertiseType == 1 ? $t('cadplaceholder2'): $t('cadplaceholder8')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('cadLabel9')" prop="payMode">
						<el-select v-model="form.payMode" multiple :placeholder="$t('placeholder3')">
							<el-option v-for="(item,index) in payList" :key="index" :label="item.code"
								:disabled="item.isOpen" :value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<div class="form_mode" @click="add">{{$t('cadTxt7')}}</div>
					</el-form-item>
					<el-form-item prop="minLimit" :label="$t('cadLabel10')">
						<el-input v-model="form.minLimit"  type="number" :placeholder="$t('cadplaceholder3')">
							<template slot="suffix">
								USD
							</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="maxLimit" :label="$t('cadLabel11')">
						<el-input v-model="form.maxLimit"  type="number" :placeholder="$t('cadplaceholder4')">
							<template slot="suffix">
								USD
							</template>
						</el-input>
						<!-- <div class="form_max">{{$t('cadTxt8')}}</div> -->
					</el-form-item>
					<el-form-item :label="$t('cadLabel14')" prop="desc">
						<el-input type="textarea" v-model="form.remark" :placeholder="$t('cadplaceholder5')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('cadLabel12')">
						<el-switch v-model="form.auto" :width="72" :active-value="0" :inactive-value="1"
							inactive-color="#E6E7EA" active-color="rgba(255,255,255,0)"></el-switch>
						<div class="form_auto line-2">{{$t('cadTxt9')}}</div>
					</el-form-item>
					<el-form-item v-show="form.auto == 0" prop="autoword" :label="$t('cadLabel16')">
						<el-input type="textarea" v-model="form.autoword" :placeholder="$t('cadplaceholder7')">
						</el-input>
					</el-form-item>
					<el-form-item prop="jyPassword" :label="$t('cadLabel13')">
						<el-input v-model="form.jyPassword" show-password type="password" :placeholder="$t('placeholder2')"></el-input>
					</el-form-item>
					<el-button type="primary" class="btn form_btn" @click="onSubmit('form')">{{$t('submit')}}</el-button>
				</el-form>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		coinAll,
		advertiseDetail,
		advertiseUpdate
	} from '@/api/api/otc'
	import {
		getCountry,
		paymentList,
		// businessStatus,
		getRecords
	} from '@/api/api/user'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {

			const fixedPCheck = (rule, value, callback) => {
				if (this.form.priceType == 0) {
					if (value === "") {
						callback(new Error(this.$t("aderr2")));
					} else if (!/^[0-9]+(.[0-9]{2})?/.test(value)) {
						callback(new Error(this.$t("aderr1")));
					} else {
						callback();
					}
				} else {
					callback();
				}
			};
			const premisePriceCheck = (rule, value, callback) => {
				if (this.form.priceType == 1) {
					if (!value || value == 0) {
						return callback(new Error(this.$t("aderr2")));
					} else if (Number.isNaN(Number(value))) {
						callback(new Error(""));
					} else if (value < 0 || value > 20) {
						callback(new Error(this.$t("aderr1")));
					} else {
						callback();
					}
				} else {
					callback();
				}
			};
			const numberCheck = (rule, value, callback) => {
				if (value === "" || value == 0) {
					callback(new Error(this.$t("aderr1")));
				} else if (Number.isNaN(Number(value))) {
					callback(new Error(this.$t("aderr1")));
				} else {
					callback();
				}
			};
			const minCheck = (rule, value, callback) => {
				if (!value || value == 0) {
					return callback(new Error(this.$t("caderr1")));
				} else if (!/^\d+$/.test(value)) {
					callback(new Error(this.$t("caderr5")));
				} else if (parseFloat(value) < 100) {
					callback(new Error(this.$t("caderr2")));
				}
				if (
					this.form.maxLimit &&
					this.form.maxLimit != 0 &&
					parseFloat(value) > this.form.maxLimit - 0
				) {
					// console.log(this.form.maxLimit)
					callback(new Error(this.$t("caderr3")));
				} else {
					callback();
				}
			};
			const maxCheck = (rule, value, callback) => {
				let priceNow = (this.price + "").replace(/,/g, "").replace(/[^\d|.]/g, "") - 0;
				priceNow = this.round(this.mul(priceNow, this.form.number), 8);

				if (!value || value == 0) {
					return callback(new Error(this.$t("caderr4")));
				} else if (!/^[0-9]+(.[0-9]{2})?$/.test(value)) {
					callback(new Error(this.$t("caderr5")));
				} else if (parseFloat(value) < parseFloat(this.form.minLimit)) {
					callback(new Error(this.$t("caderr6")));
				} else if (parseFloat(value) > parseFloat(priceNow)) {
					callback(
						new Error(this.$t("caderr7") + priceNow + "CNY！")
					);
				} else {
					callback();
				}
			};
			return {
				form: {
					advertiseType: 1,
					priceType: 1,
					auto: 1,
					marketPrice: '',
					coinUnit: '',
					localCurrency: '',
					countryName: '',
					price: '',
					number: '',
					minLimit: '',
					maxLimit: '',
					payMode: []
				},
				price: "",
				rules: {
					premiseRate: [{
						validator: premisePriceCheck,
						message: this.$t("aderr3"),
						trigger: "blur"
					}],
					price: [{
						validator: fixedPCheck,
						message: this.$t("aderr4"),
						trigger: "blur"
					}],
					number: [{
						required: true,
						validator: numberCheck,
						message: this.$t("aderr5"),
						trigger: "blur"
					}],
					minLimit: [{
						required: true,
						validator: minCheck,
						trigger: "blur"
					}],
					maxLimit: [{
						required: true,
						validator: maxCheck,
						trigger: "blur"
					}],
					jyPassword: [{
						required: true,
						message: this.$t("chtip8"),
						trigger: "blur"
					}],
					payMode: [{
						required: true,
						type: "array",
						min: 1,
						message: this.$t("caderr8"),
						trigger: "change"
					}]
				},
				country: [],
				payList: [],
				location: "en_US",
				coinList: [],
				gongshi: 1,
				cankao: ''
			};
		},
		created() {
			this.location = localStorage.getItem('lang')
			if (this.$route.query.id) {
				this.getDetailAd();
			}
		},
		mounted() {
			this.getList()
			this.payType()
			this.getCoin()
		},
		computed: {
			premisePriceHistory() {
				return this.form.premiseRate;
			},
			fixedPriceHistory() {
				return this.form.price;
			}
		},
		watch: {
			premisePriceHistory(newValue, oldValue) {
				console.log(oldValue)
				this.gongshi = 1 + newValue / 100;
				if (newValue == '') {
					this.price = this.form.marketPrice
				} else {
					this.price = (this.price * this.gongshi).toFixed(2);
				}
			},
			fixedPriceHistory(newValue, oldValue) {
				console.log(oldValue)
				if (newValue == '') {
					this.price = this.form.marketPrice
				} else {
					this.price = newValue;
				}
			},
		},
		methods: {
			payType() {
				paymentList().then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.forEach(item => {
							item.isOpen = true
							arr.push(item)
						})
						this.payList = arr

					} else {
						this.$message.error(res.message)
					}
				})
			},
			getList() {
				this.getAccount();
				// businessStatus().then(res => {
				// 	if (res.data.certifiedBusinessStatus == 2) {

				// 	} else {
				// 		this.$message.warning(this.$t('business'));
				// 		this.$router.push("/identbusiness");
				// 	}
				// })


				getCountry().then(res => {
					if (res.code == 0) {
						this.country = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			getDetailAd() {
				advertiseDetail({
					id: this.$route.query.id
				}).then(res => {
					if (res.code == 0) {
						console.log(res)
						this.form = res.data
						this.form.countryName = res.data.country.zhName;
						this.form.localCurrency = res.data.country.localCurrency;
						this.form.payMode = (res.data.payMode + "").split(",");
						this.price = res.data.price;
					} else {
						this.$message.error(res.message);
					}
				})
			},
			getCoin() {
				let currency = this.form.localCurrency ? this.form.localCurrency : "CNY";
				coinAll({
					currency: currency
				}).then(res => {
					if (res.code == 0) {
						this.coinList = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			getAccount() {
				getRecords().then(res => {
					if (res.code == 0) {
						let records = res.data;
						for (let i = 0; i < records.length; i++) {
							for (let j = 0; j < this.payList.length; j++) {
								if (records[i].typeName == this.payList[j].code) {
									this.payList[j].isOpen = false;
								}
							}
						}
					} else {
						this.$message.error(res.message)
						this.$router.push("/collection");
					}
				})
			},
			selectChange(value) {
				var arr = this.country.filter(item => {
					return item.zhName == value
				})
				this.form.localCurrency = arr[0].localCurrency
				this.getCoin()
			},
			mul(a, b) {
				var c = 0,
					d = a.toString(),
					e = b.toString();
				try {
					c += d.split(".")[1].length;
				} catch (f) {
					console.log(f)
				}
				try {
					c += e.split(".")[1].length;
				} catch (f) {
					console.log(f)
				}
				return (
					Number(d.replace(".", "")) *
					Number(e.replace(".", "")) /
					Math.pow(10, c)
				);
			},
			round(v, e) {
				var t = 1;
				for (; e > 0; t *= 10, e--);
				for (; e < 0; t /= 10, e++);
				return Math.round(v * t) / t;
			},
			add() {
				this.$router.push({
					path: '/collection'
				})
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var params = {};
						params["id"] = this.$route.query.id;
						params["price"] = (this.price + "").replace(/[^\d|.]/g, "") - 0;
						params["advertiseType"] = this.form.advertiseType;
						params["auto"] = this.form.auto;
						params["autoword"] = this.form.autoword;
						params["coinId"] = this.coin.id;
						params["coinUnit"] = this.form.coinUnit;
						params["minLimit"] = this.form.minLimit;
						params["maxLimit"] = this.form.maxLimit;
						params["priceType"] = this.form.priceType;
						params["premiseRate"] = this.form.premiseRate;
						params["remark"] = this.form.remark;
						params["number"] = this.form.number;
						params["pay"] = this.form.payMode;
						params["country"] = this.form.countryName;
						params["jyPassword"] = this.form.jyPassword;
						advertiseUpdate(params).then(res => {
							console.log(res)
							if (res.code == 0) {
								this.$message.success(res.message);
								setTimeout(() => {
									this.$router.push("/ad");
								}, 3000);
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>
